import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import camelcaseKeys from "camelcase-keys";

import type { AuditSearchParams } from "@/features/auditReview";
import { apiSlice } from "@/lib/rtkquery";
import {
  ApiResponse,
  AuditDTO,
  AuditTypeDTO,
  PaginatedApiResponse,
  QuestionDTO,
  SearchParams,
} from "@/types";

type AuditResponse = ApiResponse<AuditDTO>;

type AuditTypesResponse = ApiResponse<AuditTypeDTO[]>;
type AuditTypeResponse = ApiResponse<AuditTypeDTO>;

type AuditsResponse = PaginatedApiResponse<Omit<AuditDTO, "auditChecks">[]>;

type QuestionResponse = ApiResponse<QuestionDTO[]>;

export const auditApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAudit: build.query<AuditDTO, string | undefined>({
      query: (id) => `api/v1/audits/${id}`,
      transformResponse: (response: AuditResponse) =>
        camelcaseKeys(response.results, { deep: true }),
    }),
    getAudits: build.query<AuditsResponse, SearchParams & AuditSearchParams>({
      query: (data) => ({ url: "api/v1/audits", params: data }),
      transformResponse: (response: AuditsResponse) =>
        camelcaseKeys(response, { deep: true }),
    }),
    getFirstAudit: build.query<AuditDTO | undefined, AuditSearchParams>({
      query: (data) => ({ url: "api/v1/audits/first", params: data }),
      transformResponse: (response: AuditsResponse) => {
        if (response.results.length > 0) {
          return camelcaseKeys(response.results[0], { deep: true });
        }
      },
      keepUnusedDataFor: 5,
    }),
    getQuestions: build.query<QuestionDTO[], string | undefined>({
      query: (audit_type) => `api/v1/questions/${audit_type}`,
      transformResponse: (response: QuestionResponse) => response.results,
    }),
    getAuditType: build.query<AuditTypeDTO, string>({
      query: (auditTypeId: string) => `api/v1/audit_types/${auditTypeId}`,
      transformResponse: (response: AuditTypeResponse) => response.results,
    }),
    getAuditTypes: build.query<AuditTypeDTO[], void>({
      query: () => "api/v1/audit_types",
      transformResponse: (response: AuditTypesResponse) => response.results,
    }),
    getAuditCount: build.query<number, AuditSearchParams>({
      query: (data: AuditSearchParams) => ({
        url: "api/v1/audits",
        method: "HEAD",
        params: data,
      }),
      // @ts-expect-error data is undefined for HEAD requests. The value to find is in the headers
      transformResponse: (data: undefined, meta: FetchBaseQueryMeta) => {
        const count = meta.response?.headers.get("x-total-count");

        return Number(count) ? Number(count) : 0;
      },
    }),
    createAudit: build.mutation<
      AuditDTO,
      Omit<AuditDTO, "createdBy" | "auditType" | "children">
    >({
      query: (data) => ({
        url: "api/v1/audits",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: AuditResponse) =>
        camelcaseKeys(response.results, { deep: true }),
    }),
    updateAudit: build.mutation<
      AuditDTO,
      Partial<AuditDTO> & Pick<AuditDTO, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `api/v1/audits/${id}`,
        method: "PUT",
        body: patch,
      }),
      transformResponse: (response: AuditResponse) =>
        camelcaseKeys(response.results, { deep: true }),
    }),
    deleteAudit: build.mutation<void, string>({
      query: (id) => ({ url: `api/v1/audits/${id}`, method: "DELETE" }),
    }),
  }),
});

export const {
  useGetAuditQuery,
  useGetAuditsQuery,
  useGetQuestionsQuery,
  useGetAuditTypeQuery,
  useGetFirstAuditQuery,
  useGetAuditTypesQuery,
  useGetAuditCountQuery,
  useCreateAuditMutation,
  useUpdateAuditMutation,
  useDeleteAuditMutation,
} = auditApi;
