import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/store/index.ts";

type LocationState = {
  locationId: string;
  locationName: string;
};

const initialState: LocationState = {
  locationId: "",
  locationName: "",
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<string>) => {
      state.locationId = action.payload;
    },
    setLocationName: (state, action: PayloadAction<string>) => {
      state.locationName = action.payload;
    },
    resetLocation: () => initialState,
  },
});

export const { setLocation, setLocationName, resetLocation } =
  locationSlice.actions;
export const selectLocationId = (state: RootState) => state.location.locationId;
export const selectLocation = (state: RootState) => state.location;

export default locationSlice.reducer;
